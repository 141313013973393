import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Tooltip } from 'react-tooltip';




import './Experience.scss';
import { urlFor, client } from '../../client';

const Experience = ({id}) => {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experience"]';
  
    client.fetch(query).then((data) => {
      // Convert date strings to Date objects and sort by end date
      data.sort((a, b) => {
        const endDateA = new Date(a.date.split(' - ')[1]);
        const endDateB = new Date(b.date.split(' - ')[1]);
        return endDateB - endDateA;
      });
      setExperiences(data);
    });
  }, []);

  return (
    <div id={id} className="app__header app__flex">
      <motion.div
        whileInView={{ y: [15, 0], opacity: [0,1] }}
        transition={{ duration: 1 }}
      >
        <h2 className="head-text gradient-text">Experiences.</h2>
      </motion.div>

      <div className="app__exp-container">
        <div className="app__exp">
          {experiences.map((experience) => (
            <motion.div
              className="app__exp-item"
              key={experience.date}
              // whileInView={{ x: [15, 0], opacity: [0,1] }}
              // transition={{ duration: 2, delay: 0.2 }}
            >
              <div className='app__exp-year-container'>
                <motion.div
                  whileInView={{ x: [-15, 0], opacity: [0,1] }}
                  transition={{ duration: 2, delay: 0.2 }}
                  className="app__exp-year"
                >
                  <p className="bold-text">{experience.date}</p>
                  <img src={urlFor(experience.imageurl).toString()} alt={experience.position} />
                </motion.div>
              </div>
              <div className='app__exp-work-container'>
                <motion.div className="app__exp-works">
                  <motion.div
                    whileInView={{ x: [15, 0], opacity: [0,1] }}
                    transition={{ duration: 2, delay: 0.2 }}
                    className="app__exp-work"
                    data-tip
                    data-for={experience.position}
                    key={experience.position}
                  >
                    <h4 className="bold-text">{experience.position}</h4>
                    <p className="p-text bold-text">{experience.company}</p>
                    <p className="p-text">{experience.description}</p>
                    
                  </motion.div>
                  <Tooltip
                    id={experience.name}
                    effect="solid"
                    arrowColor="#fff"
                    className="skills-tooltip"
                  >
                    {experience.description}
                  </Tooltip>
                </motion.div>               
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Experience
