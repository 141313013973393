import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToSection = () => {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    if (hash && pathname === '/') {
      const handleScroll = () => {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

      // Delay to ensure the page has rendered
      setTimeout(handleScroll, 200);
    }
  }, [hash, pathname]);

  return null;
};

export default ScrollToSection;
