import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { About, Contact, Header, Work, Experience, Footer, SingleWork } from './container';
import { Navbar } from './components';
import './App.scss';
import ScrollToSection from './ScrollToSection';
import { Helmet } from 'react-helmet';

const HomePage = () => (
  <>
    <Header id="home" />
    <About id="about" />
    <Work id="work" />
    <Experience id="experience" />
    <Contact id="contact" />
    <Footer id="footer" />
  </>
);

const App = () => {
  return (
    <BrowserRouter>
      <div className='app'>
        <Helmet>
          <title>Anthony Qiu</title>
        </Helmet>
        <Navbar />
        <ScrollToSection />
        <Routes>
          {/* <Route path='/' element={<Navigate to="/home" />} /> */}
          <Route path='/' element={<HomePage />} />
          <Route path='/work/:slug' element={<SingleWork />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
