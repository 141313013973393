// import { motion } from 'framer-motion';

import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

const Footer = ({id}) => {
  return (
    <div id={id}>
        <a href='mailto:anthony.bo.qiu@gmail.com' className='footer__end footer__email'>anthony.bo.qiu@gmail.com</a>
        <div className="app__footer-icons">
          <a href='https://www.linkedin.com/in/abqiu/'>
            <FontAwesomeIcon icon={faLinkedin}/>
          </a>
          <a href='https://github.com/anthonyqiu'>
            <FontAwesomeIcon icon={faGithub}/>
          </a>           
        </div>
        <p className='p-text-inverted footer__end'>made with <span><FontAwesomeIcon icon={faHeart} /></span> by Anthony.</p>
    </div>
  )
}

export default Footer