import React, { useState, useEffect} from 'react';
import { motion } from 'framer-motion';

import { urlFor, client } from '../../client';
import { Link } from 'react-router-dom';

import './Work.scss';

const Work = ({id}) => {
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const query = '*[_type == "work"]';

    client.fetch(query).then((data) => {

      data.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });
      
      setWorks(data);
      setFilterWork(data);
    })
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterWork(works);
      }
      else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  return (
    <div id={id} className="app__header app__flex">
      <motion.div
        whileInView={{ y: [15, 0], opacity: [0,1] }}
        transition={{ duration: 1 }}
      >
        <h2 className="head-text gradient-text">My <span>Projects.</span></h2>
      </motion.div>

      <div className="app__work-filter app__flex">
        {['Personal', 'School', 'Design Team', 'Work', 'All'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <Link to={"/work/" + work.slug.current} key={work.slug.current}>
              <div
                className="app__work-img app__flex"
              >
                <img src={urlFor(work.imageurl).toString()} alt={work.name} />
              </div>

              <div className="app__work-content app__flex">
                <h4 className="bold-text">{work.name}</h4>
                <p className="p-text" style={{ marginTop: 10 }}>{work.description}</p>
              </div>
            </Link>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Work;