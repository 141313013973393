import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { urlFor, client } from '../../client';
import { useParams, Link } from 'react-router-dom';

import './SingleWork.scss';

const SingleWork = () => {
  const [work, setWork] = useState({ name: "", content: [] });
  const { slug } = useParams();

  useEffect(() => {
    console.log("Inside useEffect");

    const query = `*[slug.current == "${slug}"]`;

    client.fetch(query).then((data) => {
      setWork(data[0]);
    });
  }, [slug]);

  const renderBlockContent = (block) => {
    return block.children.map((child, index) => {
      let text = child.text;

      if (child.marks.length > 0) {
        child.marks.forEach((mark) => {
          const markDef = block.markDefs.find(def => def._key === mark);

          if (markDef && markDef._type === 'link') {
            text = <a href={markDef.href} target="_blank" rel="noopener noreferrer">{text}</a>;
          } else if (mark === 'strong') {
            text = <strong key={index}>{text}</strong>;
          } else if (mark === 'em') {
            text = <em key={index}>{text}</em>;
          }
        });
      }

      return (
        <React.Fragment key={index}>
          {text}
          {index < block.children.length - 1 && <br />}
        </React.Fragment>
      );
    });
  };

  const renderContent = (item, index) => {
    if (item._type === 'block') {
      const content = renderBlockContent(item);
      switch (item.style) {
        case 'h1':
          return <h1 key={index}>{content}</h1>;
        case 'h2':
          return <h2 key={index}>{content}</h2>;
        case 'h3':
          return <h3 key={index}>{content}</h3>;
        case 'h4':
          return <h4 key={index}>{content}</h4>;
        case 'h5':
          return <h5 key={index}>{content}</h5>;
        case 'h6':
          return <h6 key={index}>{content}</h6>;
        case 'blockquote':
          return <blockquote key={index}>{content}</blockquote>;
        case 'normal':
        default:
          return <p className='p-text' key={index}>{content}</p>;
      }
    } else if (item._type === 'image') {
      return (
        <img key={index} src={urlFor(item).url()} alt={work.name} />
      );
    }
    return null; // If it's neither a block nor an image
  };

  return (
    <div id='singlework' className='app__header app__flex'>
      <motion.h2
        className='head-text gradient-text'
        whileInView={{ y: [15, 0], opacity: [0,1] }}
        transition={{ duration: 1 }}
      >
        {work.name}
      </motion.h2>
      <div className="work-content">
        {work.content.map((item, index) => renderContent(item, index))}
      </div>
      <Link to={'/#work'}>
        <div className='single-work__back-button'>
        
          <p className='p-text'>Back to Works</p>
        
        </div>
      </Link>
    </div>
  );
};

export default SingleWork;
