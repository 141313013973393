import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion';

import './About.scss';
import { urlFor, client } from '../../client';

const About = ({id}) => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "about"]';

    client.fetch(query)
      .then((data) => {
        console.log("Fetched data:", data); // Debugging
        setAbouts(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error); // Debugging
      });
  }, []);

  console.log("Abouts:", abouts); // Debugging

  return (
    <div id={id} className="app__flex">
      <motion.div
        whileInView={{ y: [15, 0], opacity: [0,1] }}
        transition={{ duration: 1 }}
      >
        <h2 className='head-text gradient-text'>
        About Me.
        </h2>
      </motion.div>

      <p className='p-text-large'>
      Currently I am busy studying mechanical engineering at the University of Waterloo. While studying is my focus, I also work on fun side projects, and hone other relevant engineering skills. When I am not working, you can find me spending my time hitting the gym, playing volleyball, or learning martial arts. Below are some of my hobbies:
      </p>

      <motion.div
        className='app__profiles'
        
      >
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: [0,1]}}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 1, type: 'tween' }}
            className='app__profile-item'
            key={about._id}
          >
            <img src={urlFor(about.imageurl).toString()} alt={about.name} />
            <h2 className='bold-text' style={{marginTop: 20 }}>{about.name}</h2>
            {/* <p className='p-text' style={{marginTop: 10 }}>{about.description}</p> */}
          </motion.div>
        ))}
      </motion.div>
    </div>
  )
}

export default About